import { BaseDto } from './base-dto';

export class resource extends BaseDto {
    public ResourceID!:number;
    public ResourceName!:string;
    public Color !: string;
    public Icono !: string;
    public GetResourcePendiente(){
        this.ResourceID = 0;
        this.ResourceName="Cita pendiente";
        this.Color ="orange";
        this.Icono="lens";
        return this;
    }
 } 
