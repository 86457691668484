import { BaseDto } from './base-dto';
import { resource } from './resource';
import { paciente } from './paciente-dto';

export class appointment extends BaseDto {
    public StartDate!:Date;
    public EndDate!:Date;
    public AllDay !: boolean;
    public Subject !: string;
    public Description !: string;
    public Status !: number;
    public CustomField1 !: number;
    public Paciente !: paciente;
    public ResourceID!:number;
    public Resource!:resource;
    public UniqueID!:number;
 } 
