import { store } from '@/store/store';
import { resource } from '@/shared/dtos/resource';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'resourceModule',
    store,
    dynamic: true
})
class resourceModule extends VuexModule {
    public resources: resource[] = [];
    public resource: resource = new resource();

    @Action({ commit: 'onGetresources' })
    public async getresources() {
        if (this.resources.length > 0) {
            return this.resources;
        }
        return await ssmHttpService.get(API.resources);
    }

    @Action({ commit: 'onGetresource' })
    public async getresource(id: any) {
        return await ssmHttpService.get(API.resources + '/' + id);
    }

    @Mutation
    public onGetresources(res: resource[]) {
        res.forEach(element => {
            switch (element.ResourceID) {
                case 0:
                    element.Color = 'orange';
                    element.Icono = 'lens';
                    break;
                case 1:
                    element.Color = 'green';
                    element.Icono = 'lens';
                    break;
                case 2:
                    element.Color = 'red';
                    element.Icono = 'lens';
                    break;

            }
        });
        this.resources = res ? res.map((x) => new resource(x)) : [];;
    }

    @Mutation
    public onGetresource(res: resource) {

        switch (res.ResourceID) {
            case 0:
                res.Color = 'orange';
                break;
            case 1:
                res.Color = 'green';
                break;
            case 2:
                res.Color = 'red';
                break;

        }
        this.resource = new resource(res);
    }
}
export default getModule(resourceModule);