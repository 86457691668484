import { store } from '@/store/store';
import { appointment } from '@/shared/dtos/appointment';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import resourceModule from './resource-module';
import pacienteModule from './paciente-module';
import { UtilsFiles } from '@/utils/utils-files';

@Module({
    namespaced: true,
    name: 'appointmentModule',
    store,
    dynamic: true
})
class appointmentModule extends VuexModule {
    public appointments: appointment[] = [];
    public appointment: appointment = new appointment();

    @Action({ commit: 'onGetappointments' })
    public async getappointments() {
        return await ssmHttpService.get(API.appointments);
    }

    @Action({ commit: 'onGetappointment' })
    public async getappointment(id: any) {
        return await ssmHttpService.get(API.appointments + '/' + id);
    }

    @Action
    public async guardarappointment(appointment: appointment) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.appointments, appointment.toJson());
        //this.getappointments();
    }

    @Action
    public async modificarappointment(appointment: appointment) {
        await ssmHttpService.put(API.appointments + '/' + appointment.UniqueID, appointment);
        //this.getappointments();
    }

    @Action
    public async eliminarappointment(appointment: appointment) {
        await ssmHttpService.delete(API.appointments + '/' + appointment.UniqueID, null, false);
        this.Deleteappointment(appointment);
        // this.getappointments();
    }

    @Action({ commit: 'onGetdescargapdf' })
    public async descargarappointmentPDF(appointment: appointment) {
        return await ssmHttpService.get(API.appointments + '/pdf_recordatorio_cita/' + appointment.UniqueID, null, false, true);
    }

    @Mutation
    public async onGetappointments(res: appointment[]) {
        let aux: appointment[] = [];
        aux = res ? res.map((x) => new appointment(x)) : [];
        if (resourceModule.resources.length === 0) {
            await resourceModule.getresources();
        }
        if (pacienteModule.pacientes.length === 0) {
            await pacienteModule.getpacientes();
        }

        aux.forEach(AppoimentElement => {
            resourceModule.resources.forEach(ResourceElement => {
                if (AppoimentElement.ResourceID === ResourceElement.ResourceID) {
                    AppoimentElement.Resource = ResourceElement;
                }
            });
            for (let i = 0; i < pacienteModule.pacientes.length; i++) {
                const element = pacienteModule.pacientes[i];
                if (AppoimentElement.CustomField1 === element.numero) {
                    AppoimentElement.Paciente = element;
                    break;
                }
            }
        });
        this.appointments = aux;

    }
    @Mutation
    public Addappointment(add: appointment) {
        resourceModule.resources.forEach(ResourceElement => {
            if (add.ResourceID === ResourceElement.ResourceID) {
                add.Resource = ResourceElement;
            }
        });

        pacienteModule.pacientes.forEach(PacienteElement => {
            if (add.CustomField1 === PacienteElement.numero) {
                add.Paciente = PacienteElement;
            }
        });
        this.appointments.push(add);
    }
    @Mutation
    public async onGetappointment(res: appointment) {
        if (resourceModule.resources.length === 0) {
            await resourceModule.getresources();
        }
        if (pacienteModule.pacientes.length === 0) {
            await pacienteModule.getpacientes();
        }
        resourceModule.resources.forEach(ResourceElement => {
            if (res.ResourceID === ResourceElement.ResourceID) {
                res.Resource = ResourceElement;
            }
        });

        pacienteModule.pacientes.forEach(PacienteElement => {
            if (res.CustomField1 === PacienteElement.numero) {
                res.Paciente = PacienteElement;
            }
        });
        this.appointment = new appointment(res);
    }

    @Mutation
    public async onGetdescargapdf(response: any) {
        UtilsFiles.CreateURLFromBase64AndOpen(response.data, 'application/pdf');
    }

    @Mutation
    public async Deleteappointment(res: appointment) {
        for (let i = 0; i < this.appointments.length; i++) {
            const element = this.appointments[i];
            if (res.UniqueID === element.UniqueID) {
                this.appointments.splice(i, 1);
                break;
            }
        }
    }

}
export default getModule(appointmentModule);